import { IonButton, IonContent, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import MxConfetti from '../Shared/services/AddConfetti';
import AnnouncementService from '../Shared/services/AnnouncementService';
import ImageService from '../Shared/services/ImageService';

export default function Announcement() {
  const [announcement, _announcement] = useState<any>();
  const history = useHistory();
  const { id } = useParams<any>();

  useEffect(() => {
    AnnouncementService.getAnnouncement(id).then((res) => {
      _announcement(res.data);
      // set all announcements to seen
      // if has not been seen

      if (!res.data.hasSeenAnnouncement) {
        AnnouncementService.AnnouncementSeen(id);
      }
    });
  }, [id]);

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content '>
        {announcement && (
          <div className='main-container' id='rain-confetti'>
            {announcement.hasSeenAnnouncement == false &&
              MxConfetti.addConfetti(announcement.title)}
            {/** Don't show the picture if videoLink is not null */}
            {announcement.picture && announcement.hidePicture != true && announcement.videoLink == null ? (
              <div
                style={{
                  backgroundImage: `url(${ImageService.Url(announcement.picture)})`,
                  height: 300,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              ></div>
            ) : (
              <div style={{ height: 70 }}></div>
            )}
            {announcement.videoLink != null && announcement.videoLink.includes('youtube.com') && (
              <div>
                <iframe
                  width='100%'
                  height='300'
                  src={announcement.videoLink.replace('watch?v=', 'embed/')}
                  title='Video'
                  frameBorder={0}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                ></iframe>
              </div>
            )}
            <div style={{ padding: 24 }}>
              <h2>{announcement.title}</h2>
              <div className='display-linebreak'>{parse(announcement.body)}</div>
              <br />
              <br />
              {announcement.link &&
                announcement.link.split(',').map((item: string, index: number) => (
                  <IonButton
                    style={{ marginBottom: 16 }}
                    key={index}
                    onClick={() => {
                      if (item.includes('://')) {
                        window.open(item, '_blank');
                      } else {
                        history.push(item);
                      }
                    }}
                    expand='block'
                    color='light'
                  >
                    {announcement.linkText && announcement.linkText.split(',')[index]
                      ? announcement.linkText.split(',')[index]
                      : 'View'}
                  </IonButton>
                ))}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
