import axios from 'axios';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const memberState = atom({
  key: 'memberState', // unique ID (with respect to other atoms/selectors)
  default: {},
});

// darkmodeState
export const darkmodeState = atom({
  key: 'darkmodeState', // unique ID (with respect to other atoms/selectors)
  default: false,
});

export const chatBotState = atom<string | undefined>({
  key: 'memberChatBotIdState',
  default: undefined
}); 

const memberService = {
  async getMembers(members: any) {
    if (typeof members == 'undefined') {
      members = [];
    }
    return axios.post(`${APIURL}/Members/GetMembers`, members);
  },
  async OnboardingStarted() {
    return axios.get(`${APIURL}/Members/OnboardingStarted`);
  },
  //acceptTerms
  async acceptTerms() {
    return axios.put(`${APIURL}/Members/AcceptTerms`);
  },
  async getMember() {
    return axios.get(`${APIURL}/Members/GetCurrentMember`);
  },
  async getWallets() {
    return axios.get(`${APIURL}/Members/GetWallets`);
  },
  async balanceMember() {
    return axios.get(`${APIURL}/Members/BalanceCurrentMember`);
  },
  async postMember(member: any) {
    return axios.post(`${APIURL}/Members/PostMember/`, member);
  },
  async postMemberPicture(file: any) {
    return axios.post(`${APIURL}/Members/PostMemberPicture/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async postEnergy(energy: any) {
    return axios.post(`${APIURL}/Energy/PostEnergy`, energy);
  },
  async putMember(member: any) {
    return axios.put(`${APIURL}/Members/PutMember`, member);
  },
  async getAddresses() {
    return axios.get(`${APIURL}/Addresses/GetAddresses`);
  },
  async getAddress(id: number) {
    return axios.get(`${APIURL}/Addresses/GetAddress/${id}`);
  },
  async putAddress(id: number, address: any) {
    return axios.put(`${APIURL}/Addresses/PutAddress/${id}`, address);
  },
  async postAddress(address: any) {
    return axios.post(`${APIURL}/Addresses/PostAddress`, address);
  },
  async deleteAddress(id: number) {
    return axios.delete(`${APIURL}/Addresses/DeleteAddress/${id}`);
  },
  // get ephemeral keys
  async getEphemeralKey(code: number, cardId: number) {
    return axios.get(`${APIURL}/Members/GetEphemeralKey/${code}/${cardId}`);
  },
  // get team
  async getTeam() {
    return axios.get(`${APIURL}/Members/GetTeam`);
  },

  // Send Code to SMS
  async sendSmsCode() {
    return axios.post(`${APIURL}/Members/SendSmsCode`);
  },
  // Send Code to Email
  async sendEmailCode() {
    return axios.post(`${APIURL}/Members/SendEmailCode`);
  },
  // Validate Code
  async validateSmsCode(code: number) {
    return axios.post(`${APIURL}/Members/ValidateCode/`, { code });
  },
  // order physical card
  async orderPhysicalCard(addressId: number) {
    return axios.post(`${APIURL}/Members/OrderPhysicalCard`, { addressId });
  },
  // get card holder cards
  async getCards() {
    return axios.get(`${APIURL}/Members/GetCards`);
  },
  // activate card
  async activateCard(cardId: number) {
    return axios.post(`${APIURL}/Members/ActivateCard/${cardId}`);
  },
  // deactivate card
  async deactivateCard(cardId: number) {
    return axios.post(`${APIURL}/Members/DeactivateCard/${cardId}`);
  },
  async getOnboardingStatus(step: string) {
    return axios.get(`${APIURL}/Members/GetOnboardingStatus/${step}`);
  },
  async updateOnboardingStatus(step: string) {
    return axios.post(`${APIURL}/Members/UpdateOnboardingStatus/${step}`, {
      isCompleted: true,
    });
  },
  async getChatBotId(){
    return axios.get<string>(`${APIURL}/Members/GetChatBotId`);
  }
};

export default memberService;
